import React, { useRef, useState, useEffect } from 'react';

import ExperienceCard from '../../components/ExperienceCard';

import { experimentalStyled as styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import '../../styles/Experiences.scss';

function AdExperiences(props) {
  const campaigns = props.campaigns;

  useEffect(() => {
  }, [campaigns]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <div className="experiences">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 2, md: 12 }}
        >
          {campaigns && campaigns.filter(campaign => campaign.type === 'AD').map((campaign) => (
            <Grid item xs={1} sm={2} md={4} key={campaign.id}>
              <ExperienceCard campaign={campaign} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default AdExperiences;
