import React, { useState, useEffect, useRef } from 'react';
import { db, analytics } from '../../modules/firebase';

import '../../styles/InteractiveAd.scss';
import AdBadge from './AdBadge';

function LowerThird(props) {
  const active = props.active;
  const setActive = props.setActive;
  const video = props.video;
  const adHasVideo = props.adHasVideo;
  const setAdHasVideo = props.setAdHasVideo;
  const setRequestedTime = props.setRequestedTime;
  const currentButtonPress = props.currentButtonPress;
  const campaign = props.campaign;
  const playerRef = props.playerRef
  const playerState = props.playerState;
  const setPlayerState = props.setPlayerState;
  const onPlayerEnd = props.onPlayerEnd;
  const [videoExists, setVideoExists] = useState(false);
  const [viewable, setViewable] = useState(true);
  const [seconds, setSeconds] = useState(15);
  const [adVideo, setAdVideo] = useState(null);
  const hasRunInitialSetup = useRef(false);

  useEffect(() => {
    // Run only once
    if (!hasRunInitialSetup.current) {
      // Initial load
      checkAndLoadVideo();

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }

    // Update handleKeyDown whenever currentButtonPress changes
    handleKeyDown();
  }, [currentButtonPress]);

  useEffect(() => {
    const adInterval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
      if (seconds === 0) {
        setSeconds(0);
        closeLowerThird();
      }
    }, 1000);
    return () => clearInterval(adInterval);
  }, [seconds]);

  const handleKeyDown = () => {
    if (currentButtonPress) {
      currentButtonPress.preventDefault(); // Prevents scrolling
      if (currentButtonPress.key == 'Delete') {
        closeLowerThird();
      }
    }
  };

  // Play ad campaign video
  function checkAndLoadVideo() {
    if (campaign?.video) {
      setAdHasVideo(true);
      setVideoExists(true);
      setAdVideo(campaign.video);
      setPlayerState({
        ...playerState,
        sources: [{
          src: campaign.video,
          type: 'application/x-mpegURL'
        }]
      });
      setRequestedTime(0);
    }
  }

  function closeLowerThird() {
    setViewable(false);
    setActive(false);
  }

  const highlightOffer = (offerMessage) => {
    const highlightColor = "yellow";
    const regex = /(\d+)% off/gi;

    if (!offerMessage) {
      return offerMessage;
    }

    const matches = offerMessage.match(regex);
    const parts = offerMessage.split(regex);
    
    return (
      <React.Fragment key={0}>
        {parts[0]}
        {matches && matches[0] ? (
          <span style={{ color: highlightColor }}>
            {matches[0]}
          </span>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div id="lowerThird" key="lower-third" className="lower-third">
      <AdBadge seconds={seconds} />
      { campaign.message ? [
        <div className="advertiser-message">
          <div className="ad-message-container">
            { campaign.message.title ? [ <label className="title">{ campaign.message.title }</label> ] : null }
            { campaign.message.description ? [ <label className="description">{ campaign.message.description }</label> ] : null }
          </div>
        </div>
      ] : null }
      { videoExists ? [
        <div className="advertiser-prompt">
          <img src="https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751" alt="Apple TV Remote" />
          <div className="ad-prompt-container">
            <label className="ad-offer-message">{highlightOffer(campaign.cta[0].subTitle)}</label>
            <label className="ad-cta-prompt">{campaign.cta[0].description}</label>
          </div>
        </div>
      ] : [
        <div className="advertiser-prompt">
        <div className="ad-prompt-container">
          <div className="ad-offer-container">
            <div className="ad-offer-copy">
              <label className="sub-title">{campaign.cta[0].subTitle}</label>
              <label className="message">{campaign.sponsorMessage}</label>
            </div>
            <img className="logo" src={campaign.logo} alt="Logo" />
          </div>
        </div>
        </div>
      ] }
      <div className="gradient-background"></div>
    </div>
  );
}

export default LowerThird;
