import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import { db, analytics } from '../../modules/firebase';
import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UserContext } from '../../Context';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { ConstructionOutlined } from '@mui/icons-material';

import '../../styles/InteractiveAd.scss';
import { Query } from 'firebase/firestore';

function EndCard(props) {
  const campaignId = props.campaignId;
  const currentTime = props.currentTime;
  const campaign = props.campaign;
  const active = props.active;
  const setActive = props.setActive;
  const playerState = props.playerState;
  const setPlayerState = props.setPlayerState;
  const { eventId } = useParams();
  const triviaRef = db.collection('polls').where('slug', '==', eventId);
  const [trivia] = useCollectionData(triviaRef, { idField: 'id' });
  const [currentTrivia, setCurrentTrivia] = useState(null);
  const [total, setTotal] = useState(0);
  const increment = firebase.firestore.FieldValue.increment(1);
  const [myVote, setMyVote] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [currentActive, setCurrentActive] = useState(true);
  const [viewable, setViewable] = useState(false);
  const [eventTrivia, setEventTrivia] = useState(null);
  const [seconds, setSeconds] = useState(10);
  const { currentUser } = useContext(UserContext);
  const elementRef = useRef(null);

  useEffect(() => {
    // Initial load    
    if (eventTrivia === null) {
      loadTrivia();
    } else {
      // Loop through array of objects to match time match with current time
      eventTrivia.forEach(function(option) {
        if (option.time == currentTime.toFixed(0) && currentTrivia !== option) {
          setCurrentTrivia(option);
          setViewable(true);
          setCurrentActive(true);
          setSeconds(10);
          setActive(true);

          setPlayerState(playerState.src(campaign.adVideo));
        }
      });

      if (viewable && currentActive) {
        if (seconds > 0) {
          setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
          setSeconds(0);
          setCurrentActive(false);
        }
      }
    }
  }, [currentTime]);

  function filterByValue(array, string) {
    return array.filter(o => 
      Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
  }

  function loadTrivia() {
    if (trivia && trivia.length > 0) {
      setEventTrivia(trivia);
    }
  }

  const updateUserLeaderboard = async() => {
    const increaseBy = firebase.firestore.FieldValue.increment(currentTrivia.points);
    const leaderboard = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', eventId);
    let userData = {
      uid: currentUser.uid,
      score: increaseBy,
      banned: false,
    };
    
    await leaderboard.limit(1).get().then((query) => {
      const loadedLeaderboard = query.docs[query.docs.length - 1];
      const loadedLeaderboardRef = loadedLeaderboard.ref;
      return loadedLeaderboardRef.set({ [currentUser.uid]: userData }, {merge:true});
    });
  }

  const addUserToLeaderboard = async() => {
    const leaderboard = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', eventId);
    let userData = {
      uid: currentUser.uid,
      score: currentTrivia.points,
      banned: false,
    };

    console.log(leaderboard);

    await leaderboard.limit(1).get().then((query) => {
      const loadedLeaderboard = query.docs[query.docs.length - 1];
      const loadedLeaderboardRef = loadedLeaderboard.ref;
      return loadedLeaderboardRef.set({ [currentUser.uid]: userData }, {merge:true});
    });
  }

  function triviaSubmit(vote) {
    setMyVote(vote);
    if (vote.label === currentTrivia.answer) {
      setIsCorrect(true);
      console.log('you got it right!');

      // Send to update score within leaderboard
      const leaderboardRef = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', eventId);
      const userLeaderboardRef = leaderboardRef.where(firebase.firestore.FieldPath.documentId(), '==', currentUser.uid);

      leaderboardRef.limit(1).get().then((query) => {
      //leaderboardRef.get().then((query) => {
        // See if user is on the leaderboard
        if (!query.empty) {
          console.log('event leaderboard exists in db');
          const leaderboard = query.docs[0].data();

          if (leaderboard[currentUser.uid] !== undefined) {
            // User exists in record and update score
            console.log('user exists in leaderboard!');
            updateUserLeaderboard();
          } else {
            // User does not exist and need to add to record
            console.log('user record doesnt exist in leaderboard');
            addUserToLeaderboard();
          }
        }
      });
    }

    let triviaSnapshot = currentTrivia;
    triviaSnapshot.options.map((response) => {
      if (response.label === vote.label) {
        response.total++;
        triviaSnapshot.total++;
      }
    });

    if (currentActive) {
      db.collection('polls')
        .where('id', '==', currentTrivia.id)
        .where('title', '==', currentTrivia.title)
        .limitToLast(1)
        .orderBy('time')
        .get()
        .then((query) => {
          // Getting 2 items in case there is a duplicate, and applying to latest poll in array
          // TODO: possible to move this to a new collection under campaign vs currently in seperate polls collection
          const loadedTrivia = query.docs[query.docs.length - 1];
          const loadedTriviaRef = loadedTrivia.ref;
          loadedTriviaRef.update(triviaSnapshot);
          /*
          loadedTrivia.ref.update({
            total: increment,
          });
          */
        });
      setCurrentActive(false);
      firebase.analytics().logEvent('trivia_played');
    }
  }

  function closeTrivia() {
    console.log('closing trivia');
    setViewable(false);
    setActive(false);
  }

  function focusElement() {
    elementRef.current.focus();
  }

  const highlightOffer = (offerMessage) => {
    const highlightColor = "yellow";
    const regex = /(\d+)% off/gi;

    if (!offerMessage) {
      return offerMessage;
    }

    const matches = offerMessage.match(regex);
    const parts = offerMessage.split(regex);
    
    return (
      <React.Fragment key={0}>
        {parts[0]}
        {matches && matches[0] ? (
          <span style={{ color: highlightColor }}>
            {matches[0]}
          </span>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div className={'triviaAd ' + (currentActive ? 'active ' : 'inactive ') + (viewable ? 'viewable ' : '')} 
    style={{backgroundImage: viewable ? `url(${campaign.background})` : `none`}}
    >
      {viewable
        ? [
          <>
            <label className="ad-badge">Ad</label>
            <div className="trivia-container" key={currentTrivia.id}>
              <div className="component-container">
                <h2>{currentTrivia.title}</h2>
                <ol className="list-unstyled">
                  {currentTrivia.options.map((option) => {
                    let pollStyle = {
                      width: `${(option.total / currentTrivia.total) * 100}%`,
                    };
                    let optionValue = option.total;
                    if (optionValue !== 0) {
                      optionValue = (
                        (option.total / currentTrivia.total) *
                        100
                      ).toFixed(0);
                    }
                    return (
                      <li
                        key={option.label}
                        ref={elementRef}
                        onClick={(e) => triviaSubmit(option)}
                        className={
                          (myVote &&
                          !active &&
                          currentTrivia.answer === myVote.label &&
                          myVote.label === option.label
                            ? 'correct '
                            : '') +
                          (myVote &&
                          !active &&
                          currentTrivia.answer !== myVote.label &&
                          myVote.label === option.label
                            ? 'incorrect '
                            : '')
                        }
                      >
                        <label className="title">{option.label}</label>
                        {!active
                          ? [
                              <>
                                <label className="value">{optionValue}%</label>
                                {currentTrivia.answer === option.label ? (
                                  <CheckIcon />
                                ) : (
                                  <ClearIcon />
                                )}
                                <div className="bar" style={pollStyle}></div>
                              </>,
                            ]
                          : null}
                      </li>
                    );
                  })}
                </ol>
                <div className="results">
                  {viewable && (seconds > 0) ? <span className="countdown" key="counter">{seconds}</span> : null }
                  {isCorrect && !active
                    ? [<span className="response" key="corrrect">You got it right! +{currentTrivia.points} points</span>]
                    : null}
                  {!isCorrect && !active
                    ? [<span className="response" key="incorrect">Better luck next time! +0 points</span>]
                    : null}
                </div>
              </div>
            </div>,
            <div className="advertiser-message">
              <div className="ad-message-container">
                <label className="ad-message-one">{ campaign.adMessageOne }</label>
                <label className="ad-message-two">{ campaign.adMessageTwo }</label>
              </div>
              <img src={campaign.logo} alt={campaign.advertiser} />
            </div>
            <div className="advertiser-prompt">
              <img src="https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751" alt="Apple TV Remote" />
              <div className="ad-prompt-container">
                <label className="ad-offer-message">{highlightOffer(campaign.offerMessage)}</label>
                <label className="ad-cta-prompt">{campaign.ctaPrompt}</label>
              </div>
            </div>
            <div className="gradient-background" style={{background: `radial-gradient(146.58% 140.62% at 58.83% 97.82%, rgba(23, 9, 55, 0.25) 0.49%, #170937 33.82%, #170937 75.07%)`}}></div>
           </>       
          ]
        : null}
    </div>
  );
}

export default EndCard;
