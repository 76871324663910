import { FeatureBrandLogoLockup } from "../features/FeatureBrandLogoLockup";
import { FeatureGatewayMessaging } from "../features/FeatureGatewayMessaging";

export function SceneOverlay(props) {
    const campaign = props && props.campaign;
  
    return (
      <div className="scene-overlay">
        { campaign && <FeatureBrandLogoLockup 
          advertiser={campaign.advertiser}
          logo={campaign.logo}
          headline={campaign.message.title}
          message={campaign.message.description}
          hero={campaign.heroImage}
          />
        } 
        { campaign && <FeatureGatewayMessaging
          cta={campaign.cta}
          />
        }
        <div className="featured-background"></div>
      </div>
    );
  };