import React, { useContext, useState, useEffect, MouseEvent } from 'react';

import { UserContext, PlayerContext } from '../Context';

import '../styles/Admin.scss';

import { isAdmin } from '../helpers/helperFunctions';

import firebase from 'firebase/compat/app';
import { db, analytics } from '../modules/firebase';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';

// import ChatControls from '../components/Tools/Chat/ChatControls';
// import Poll from '../components/Poll/Poll';
// import Trivia from '../components/Poll/Trivia';

function Admin(props) {
  const campaigns = props.campaigns;

  const { currentUser, setCurrentUser } = useContext(UserContext);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

  }, [campaigns]);

  const selectCampaign = (campaign) => {
    setCurrentCampaign(campaign);
    handleClose();
  }

  const broadcastAd = async(campaign) => {
    const playerState = db.collection('player').where(firebase.firestore.FieldPath.documentId(), '==', currentCampaign.slug);

    let updatedTime = {
      time: campaign.time > 5 ? campaign.time - 15 : campaign.time,
      type: "PLAY",
    }

    await playerState.limit(1).get().then((query) => {
      const loadedPlayerState = query.docs[query.docs.length - 1]; // Use [0] to access the first document
      const loadedPlayerStateRef = loadedPlayerState.ref;
      return loadedPlayerStateRef.update({ event: updatedTime }, {merge:true}); // Update using update method
    });
  }

  const startOver = async() => {
    const playerState = db.collection('player').where(firebase.firestore.FieldPath.documentId(), '==', currentCampaign.slug);

    let updatedTime = {
      time: 0,
      type: "PLAY",
    }

    await playerState.limit(1).get().then((query) => {
      const loadedPlayerState = query.docs[query.docs.length - 1]; // Use [0] to access the first document
      const loadedPlayerStateRef = loadedPlayerState.ref;
      return loadedPlayerStateRef.update({ event: updatedTime }, {merge:true}); // Update using update method
    });
  }

  const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );

  return (
    <div className="admin">
      {currentUser && currentUser.id === "18bea8a7-dfc6-46d7-bc94-e360901a3001" || currentUser &&  
        (currentUser.chatName === "Yasir" || currentUser.chatName === "David" || currentUser.chatName === "Phil" || currentUser.chatName === "Alice") ? 
      [ <>
          <section className="main">
            <Container maxWidth="md">
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                className="admin-menu-button"
                aria-haspopup="true"
                variant="contained"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                Live Campaigns
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {campaigns && campaigns.map((campaign, index) => {
                  return (
                    <MenuItem key={index} onClick={() => selectCampaign(campaign)}>{campaign.title}</MenuItem>
                  );
                })}
              </Menu>
            </Container>
            { currentCampaign ? [
              <Container maxWidth="md" className="campaign-timeline">
                 <Card sx={{ minWidth: 275 }}>
                  <CardContent>
                    <h1>{currentCampaign.title}</h1>
                    <div className="timeline-container">
                    {currentCampaign && currentCampaign.campaign.script.map((adPod, index) => (
                      <Tooltip TransitionComponent={Zoom} title={adPod.type}>
                        <div 
                        key={index} 
                        className="ad-pod" 
                        style={{left: (adPod.time / currentCampaign.contentDetails.duration) * 100 + `%`}}
                        onClick={() => broadcastAd(adPod)}></div>
                      </Tooltip>
                    ))}
                      <div className="timeline-bar"></div>
                    </div>
                    { (currentCampaign && currentCampaign.video) ? [
                      <>
                      <div className="button-container">
                        <Button className="watch-interactive"><PlayArrowIcon /> Play</Button>
                        <Button className="watch-trailer" onClick={() => startOver()}><RestartAltIcon /> Start Over</Button>
                      </div>
                      </>
                    ] : null }
                  </CardContent>
                </Card>
              </Container>
            ] : null }
          </section>
        </> ] : 
      [
        <section key="notadmin">
          <h1>Please Login as Admin</h1>
        </section>,
      ]}
    </div>
  );
}

export default Admin;
