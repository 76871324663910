import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router';

import { UserContext, PlayerContext } from '../../Context';

import Chat from '../../components/Chat';
import { VideoPlayer } from '../../components/VideoPlayer';
import Remote from '../../components/Remote';
import ContentDetails from '../../components/ContentDetails';

import '../../styles/Experience.scss';

function Experience(props) {
  const campaigns = props.campaigns;
  const { eventId } = useParams();
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [currentTime, setCurrentTime] = useState(0.00);
  const [currentButtonPress, setCurrentButtonPress] = useState(null);
  const liContainerRef = useRef(null);
  const [currentState, setCurrentState] = useState(null);
  const { currentPlayerState } = useContext(PlayerContext);
  const [triviaTotalAds, setTriviaTotalAds] = useState(0);
  const [triviaScore, setTriviaScore] = useState(0);
  const [triviaTotalCorrect, setTriviaTotalCorrect] = useState(0);

  const handleKeyDown = (event) => {
    console.log(`Keydown event: ${event.key}`);
    setCurrentButtonPress(event);
    // Perform actions based on the dispatched event
  };

  useEffect(() => {
    setCurrentCampaign( campaigns && campaigns.find((c) => c.slug === eventId));

    if (currentCampaign && currentCampaign.contentDetails) {
      setCurrentState("CONTENT-DETAILS");
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [campaigns, currentCampaign]);

  return (
    <div
      className={
        (currentCampaign && currentCampaign.config.atLaunch === "VIDEO" ? 'live ' : '') +
        (currentCampaign && currentCampaign.config.deviceType === "CTV" ? 'ctv ' : '') +
        (currentCampaign && currentCampaign.config.chatEnabled ? 'chatEnabled ' : 'chatDisabled ') +
        'experience'
      }
    >
      { currentCampaign && currentCampaign.config.deviceType === "CTV" ? [
        <Remote liContainerRef={liContainerRef} key="remote" />
      ] : null }
      <section className="main">
        {currentState !== "CONTENT-DETAILS" && currentCampaign && currentCampaign.config && currentCampaign.config.atLaunch === "VIDEO"
          ? [<VideoPlayer 
              key="video-player" 
              liContainerRef={liContainerRef} 
              video={currentCampaign.video.source} 
              currentCampaign={currentCampaign} 
              setCurrentTime={setCurrentTime} 
              setCurrentState={setCurrentState}
              currentTime={currentTime} 
              currentButtonPress={currentButtonPress} 
              triviaTotalAds={triviaTotalAds}
              setTriviaTotalAds={setTriviaTotalAds}
              triviaScore={triviaScore}
              setTriviaScore={setTriviaScore}
              triviaTotalCorrect={triviaTotalCorrect}
              setTriviaTotalCorrect={setTriviaTotalCorrect}
            />]
          : null}
        {currentState == "CONTENT-DETAILS" && currentCampaign && currentCampaign.contentDetails ? [
          <ContentDetails 
            content={currentCampaign.contentDetails} 
            setCurrentState={setCurrentState}
            currentButtonPress={currentButtonPress} 
            key={"content-details"}
          />
        ] : null}
        <div
          className="bg"
          key={"background-image"}
          style={{
            backgroundImage: currentCampaign && currentCampaign.config && currentCampaign.config.deviceType === "CTV" ? 
              `url("https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room-bg-alt-2.jpg?alt=media&token=9aaf7aac-1124-4466-91f3-5fdab26fe203")`
              : `url(${currentCampaign && currentCampaign.banner})`
              ,
          }}
        ></div>
      </section>
      { currentCampaign && currentCampaign.chatEnabled ? [
          <section className="rail">
            <Chat />
          </section>
      ] : null}
    </div>
  );
}

export default Experience;
