import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';

import '../styles/ContentDetails.scss';

function ContentDetails(props) {
  const content = props.content;
  const setCurrentState = props.setCurrentState;
  const currentButtonPress = props.currentButtonPress;
  const hasRunInitialSetup = useRef(false);
  const isMounted = useRef(false);
  const [showZoomOut, setShowZoomOut] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    // Run only once
    if (!hasRunInitialSetup.current) {
      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }

    // Update handleKeyDown whenever currentButtonPress changes
    handleKeyDown();

    return () => {
      isMounted.current = false;
    };
  }, [currentButtonPress]);

  useEffect(() => {
    // Delay the zoom-out effect after a couple of seconds
    const timeout = setTimeout(() => {
      setShowZoomOut(true);
    }, 800); // Adjust the delay time as needed

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const handleKeyDown = () => {
    if (currentButtonPress && currentButtonPress.key === 'Enter') {
      // Play Movie
      setCurrentState("VIDEO-PLAYER");
    }
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  }

  const watchContent = () => {
    setCurrentState("VIDEO-PLAYER");
  }

  return (
    <div className={`content-details`}>
      <div className="content-container">
        <div className="content-logo" style={{backgroundImage: `url(${content.contentLogo})`}}></div>
        <label className="subHead">{content.subHead}</label>
        <label className="learnMore">{content.learnMore}</label>
        <ul className="badges list-unstyled">
          {content.badges.map((badge, index) => {
            return (
              <li key={index} className={badge.type}>{badge.label}</li>
            );
          })}
        </ul>
        <ul className="genres list-unstyled">
          {content.genres.map((genre, index) => {
            return (
              <li key={index}>{genre}</li>
            );
          })}
          <li>• {content.releaseYear}</li>
          <li>• {formatTime(content.duration)}</li>
        </ul>
        <label className="description">{content.description}</label>
        <div className="button-container">
          <Button className="watch"><PlayArrowIcon /> Play</Button>
          <Button className="watch-interactive" onClick={watchContent}><PlayArrowIcon /> Play Trivia Edition</Button>
          <Button className="watch-trailer"><PlayArrowIcon /> Trailer</Button>
          <Button className="add-watchlist"><AddIcon /></Button>
        </div>
      </div>
      <div className={`background-image ${showZoomOut ? 'zoom-out' : ''}`} style={{backgroundImage: `url(${content.banner})`}}></div>
    </div>
  );
}

export default ContentDetails;