import React, { useRef, useState, useEffect } from 'react';
import VideoJS from '../components/VideoJS';

import '../styles/VideoPlayer.scss';

import InteractiveContent from './InteractiveContent';

export const TestVideoPlayer = (props) => {
  const playerRef = React.useRef(null);
  const [currentTime, setCurrentTime] = useState(props.currentTime);
  const videoSrc = props.video;
  const [adHasVideo, setAdHasVideo] = useState(false);
  const [playerState, setPlayerState] = useState({
    autoplay: true,
    controls: true,
    controlsOverlay: true,
    responsive: true,
    fluid: true,
    fill: true,
    sources: [{
      src: videoSrc,
      type: 'application/x-mpegURL'
    }]
  });

  const onPlayerStart = () => {
    console.log('video player has started');
  }
  
  const onPlayerEnd = (video) => {
    console.log('video player has ended');
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.currentTime(currentTime);

    player.on('ended', () => {
      onPlayerEnd();
    });

    player.on('timeupdate', () => {
      setCurrentTime(player.currentTime());
      // console.log(player.currentTime());
    });

    player.on('dispose', () => {
      console.log('player will dispose');
    });
  };

  return (
    <div className={(adHasVideo ? 'ad-video ' : '') + ` video-player`}>
      <VideoJS options={playerState} onReady={handlePlayerReady} />
    </div>
  );
};
