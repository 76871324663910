import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../Context';

import '../styles/InteractiveAd.scss';

import { db, analytics } from '../modules/firebase';

import LowerThird from './Ads/LowerThird';
import TriviaAdPod from './Ads/TriviaAdPod';
import EndCardTrivia from './Ads/EndCardTrivia';
import Toast from './Ads/Toast';

import PauseAd from '../dxc/templates/PauseAd';

function InteractiveContent(props) {
  const currentTime = props.currentTime;
  const liContainerRef = props.liContainerRef;
  const currentButtonPress = props.currentButtonPress;
  const video = props.video;
  const campaign = props.campaign;
  const active = props.active;
  const setActive = props.setActive;
  const playerState = props.playerState;
  const onPlayerEnd = props.onPlayerEnd;
  const playerRef = props.playerRef;
  const setInteractiveType = props.setInteractiveType;
  const setAdHasVideo = props.setAdHasVideo;
  const setPlayerState = props.setPlayerState;
  const setRequestedTime = props.setRequestedTime;
  const { currentUser } = useContext(UserContext);
  const [viewable, setViewable] = useState(false);
  const [interactiveScript, setInteractiveScript] = useState(null);
  const [currentRunningScript, setCurrentRunningScript] = useState([]);
  const [currentInteractive, setCurrentInteractive] = useState(null);
  const hasRunInitialSetup = useRef(false);
  const isMounted = useRef(false);

  // Trivia
  const triviaTotalAds = props.triviaTotalAds;
  const setTriviaTotalAds = props.setTriviaTotalAds;
  const triviaTotalCorrect = props.triviaTotalCorrect;
  const setTriviaTotalCorrect = props.setTriviaTotalCorrect;

  useEffect(() => {
    isMounted.current = true;
    // Run only once
    if (!hasRunInitialSetup.current) {
      loadScript();

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    } 

    if (interactiveScript) {
      // Loop through array of objects to check match of the time of object to current time
      interactiveScript.forEach(function(option) {
        const doesNotExist = currentRunningScript.every(item => item !== option);

        if (option.time == currentTime.toFixed(0) && doesNotExist) {
          loadInteractiveContent(option);
          setCurrentRunningScript(prevState => [...prevState, option]);
        } 

        // TODO: If current interactive has video, enable squeezeback
        // setPlayerState(playerState.src(campaign.adVideo));
      });
    }

    return () => {
      isMounted.current = false;
    };
  }, [currentTime]);

  useEffect(() => {
    if (currentInteractive) {
      const playerStateRef = db.collection('player').doc(currentInteractive && currentInteractive.slug);
      const unsubscribe = playerStateRef.onSnapshot((doc) => {
        if (doc.exists) {
          //setCurrentRunningScript([]);
        }
      });
  
      return () => {
        unsubscribe(); // Clean up the listener when the component unmounts
      };
    }
  }, [currentInteractive]);

  const loadScript = () => {
    setInteractiveScript(campaign.script);
  }

  const loadInteractiveContent = (option) => {
    setCurrentInteractive(option);
    setViewable(true);
    setActive(true);
    setInteractiveType(option.type);
  }

  return (
    <div className="interactive-content" id="interactiveContent" key="interactive-content">
      { currentInteractive && currentInteractive.type === "PROMPT" && active && viewable ? [
        <LowerThird 
          campaign={currentInteractive} 
          currentButtonPress={currentButtonPress} 
          playerRef={playerRef}
          playerState={playerState} 
          setPlayerState={setPlayerState} 
          setRequestedTime={setRequestedTime}
          onPlayerEnd={onPlayerEnd}
          video={video}
          setAdHasVideo={setAdHasVideo}
          active={active}
          setActive={setActive}
          key="lower-third" 
        />
      ] : currentInteractive && currentInteractive.type === "TOAST" && active && viewable ? [
        <Toast
          campaign={currentInteractive} 
          key="toast" 
        />
      ] :currentInteractive && currentInteractive.type === "TRIVIAADPOD" && active && viewable ? [
        <TriviaAdPod 
          liContainerRef={liContainerRef} 
          campaign={currentInteractive} 
          currentButtonPress={currentButtonPress} 
          active={active} 
          setActive={setActive} 
          currentTime={currentTime} 
          playerRef={playerRef}
          playerState={playerState} 
          setPlayerState={setPlayerState} 
          onPlayerEnd={onPlayerEnd}
          video={video}
          triviaTotalAds={triviaTotalAds}
          setTriviaTotalAds={setTriviaTotalAds}
          triviaTotalCorrect={triviaTotalCorrect}
          setTriviaTotalCorrect={setTriviaTotalCorrect}
          key="trivi-ad-pod" 
        />
      ] : currentInteractive && currentInteractive.type === "ENDCARD-TRIVIA" && active && viewable ? [
        <EndCardTrivia 
          campaign={currentInteractive} 
          currentButtonPress={currentButtonPress} 
          active={active} 
          setActive={setActive} 
          paramOverride={"avengers-endgame-trivia-edition"}
          key="end-card-trivia" 
        />
      ] : null }
      <PauseAd 
        currentButtonPress={currentButtonPress} 
        key="pause-ad" 
      />
    </div>
  );
}

export default InteractiveContent;
