import React, { useRef, useState, useEffect } from 'react';

import '../styles/Remote.scss';

import firebase from 'firebase/compat/app';

function Remote(props) {
  const liContainerRef = props.liContainerRef;

  useEffect(() => {
    //console.log('remote loads');
  }, []);

  const handleButtonClick = (e, key) => {
    e.preventDefault();
    const event = new KeyboardEvent('keydown', { key });
    document.dispatchEvent(event);
    firebase.analytics().logEvent('remote_button_press', { buttonType: key } );

    // Set focus back to the parent container of <li> elements
    //console.log(liContainerRef);
    //console.log(liContainerRef.current);
    if (liContainerRef.current) {
      liContainerRef.current.focus();
    }
  };

  return (
    <div className="simulated-remote">
      <div className="button-map">
        <button className="button up" onClick={(e) => handleButtonClick(e, 'ArrowUp')}>Up</button>
        <button className="button down" onClick={(e) => handleButtonClick(e, 'ArrowDown')}>Down</button>
        <button className="button left" onClick={(e) => handleButtonClick(e, 'ArrowLeft')}>Left</button>
        <button className="button right" onClick={(e) => handleButtonClick(e, 'ArrowRight')}>Right</button>
        <button className="button select" onClick={(e) => handleButtonClick(e, 'Enter')}>Enter</button>
        <button className="button back" onClick={(e) => handleButtonClick(e, 'Back')}>Back</button>
        <button className="button playPause" onClick={(e) => handleButtonClick(e, 'Play/Pause')}>Play/Pause</button>
      </div>
      <img src="https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751" alt="Apple TV Remote" />
    </div>
  );
}

export default Remote;
