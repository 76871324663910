import React, { useState, useEffect, useRef } from 'react';

import '../../styles/InteractiveAd.scss';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';

function Toast(props) {
  const campaign = props.campaign;
  const [viewable, setViewable] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const hasRunInitialSetup = useRef(false);

  useEffect(() => {
    // Run only once
    if (!hasRunInitialSetup.current) {
      // Initial load
      setViewable(true);
      console.log('here comes the toast!');

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }
  }, [campaign]);

  useEffect(() => {
    const adInterval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
      if (seconds === 1) {
        //setViewable(false);
      }
    }, 1000);
    return () => clearInterval(adInterval);
  }, [seconds]);

  const UrgeWithPleasureComponent = () => (
    <CountdownCircleTimer
    isPlaying
    duration={10}
    size={80}
    strokeWidth={6}
    colors={'#F2DE3C'}
    onComplete={() => {
      setViewable(false);
    }}>
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
  )

  return (
    <div key="toast" className={`toast ${viewable ? 'viewable' : 'hidden'}`}>
      <div className="advertiser-prompt">
        <div className="countdown-timer">
          { UrgeWithPleasureComponent() }
        </div>
        <div className="ad-prompt-container">
          <label className="title">{campaign.title}</label>
          <label className="sub-title">{campaign.subTitle}</label>
        </div>
      </div>
    </div>
  );
}

export default Toast;
