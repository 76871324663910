export const adjectives = {
  description: 'A list of Marvel themed adjectives.',
  terms: [
    "Dynamic",
    "Heroic",
    "Mighty",
    "Astonishing",
    "Invincible",
    "Uncanny",
    "Spectacular",
    "Legendary",
    "Cosmic",
    "Incredible",
    "Radiant",
    "Supreme",
    "Timeless",
    "Fierce",
    "Adamantine",
    "Electrifying",
    "Unstoppable",
    "Phoenix-like",
    "Web-slinging",
    "Gamma-charged"
  ],
};
