import { ADMIN_USERS } from './constants';

export const isAdmin = (user) => {
  return user && user.email ? ADMIN_USERS.includes(user.email) : false;
};

export const hexToRGBA = (hex) => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)'
    );
  }
  throw new Error('Bad Hex');
};

export const slugify = (str) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const getCurrentFocusPosition = (reference) => {
  const ref = reference;
  const focusedElement = document.activeElement;
  if (focusedElement && ref.current.includes(focusedElement)) {
    const rect = focusedElement.getBoundingClientRect();
    console.log('Current focus position:', rect);
  } else {
    console.log('No focus or not within the rendered list items.');
  }
};