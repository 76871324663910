export const ADFORMATS = [
  {
    type: "AD",
    title: "Trivia Ad Pod",
    slug: "trivia-ad-pod",
    banner: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/trivia-pod.png?alt=media&token=711301f1-d67c-4948-a40b-c32f7b2f2fb3",
    config: {
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    campaign: {
      config: {
         leaderboardEnabled: true,
      },
      offer: {
        type: "DISCOUNT",
        title: "Answer right, get XX% off",
        amount: [ 0, 10, 25 ]
      },
      script: [
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          cta: [
            {
            type: "REMOTE",
            title: "It's Trivia Time!",
            description: "Use your remote to play now",
            },
          ],
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Play and Win"
          },
          offer: {
            amount: [ 0, 10, 25],
            title: "Answer right, get 25% off.",
            type: "DISCOUNT",
          },
          slug: "avengers-endgame-trivia-edition",
          time: 10,
          trivia: [
            "poll-cl4bkig6j00003f6dipn34343z",
            "poll-dl4bkig6j00003f6dipn34343z",
            "poll-cl5bkig6j00003f6dipn34343z",
          ],
          type: "TRIVIAADPOD",
        },
      ]
    },
    video: {
      config: {
        time: 8,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
  },
  {
    type: "AD",
    title: "Endcard Trivia Leaderboard",
    slug: "endcard-trivia-leaderboard",
    banner: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/trivia-pod.png?alt=media&token=711301f1-d67c-4948-a40b-c32f7b2f2fb3",
    config: {
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    campaign: {
      config: {
         leaderboardEnabled: true,
      },
      offer: {
        type: "DISCOUNT",
        title: "Answer right, get XX% off",
        amount: [ 0, 10, 25 ]
      },
      script: [
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Play and Win"
          },
          achievement: {
            badge: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fbadge.png?alt=media&token=957a726a-1af3-4d0e-bbac-a225ed8dcf19",
            title: "Marvel Lego Quiz"
          },
          offer: {
            amount: [ 0, 10, 25],
            title: "Answer right, get 25% off.",
            type: "DISCOUNT",
          },
          slug: "avengers-endgame-trivia-edition",
          time: 10145,
          type: "ENDCARD-TRIVIA",
        },
      ]
    },
    video: {
      config: {
        time: 10140,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
  },
  {
    type: "AD",
    title: "Pause Ad",
    slug: "pause-ad",
    config: {
      timeToDisplay: 5,
      playerControls: "DIM",
      atLaunch: "VIDEO",
      chatEnabled: false,
      brand: "DISNEY",
      deviceType: "CTV",
      device: {
        apple: {
          remoteImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751",
        }
      },
      styles: {
        colorPrimary: "#1c77d2",
        colorSecondary: "#f66e1f"
      },
      background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=28b55473-ffcd-4911-bdf6-05b6407ef113",
    },
    video: {
      config: {
        time: 8900,
      },
      genres: ["action", "drama"],
      releaseYear: 2019,
      source: "https://stream.mux.com/Yvjx802b2OkwWe5s5tyiKD00C3Ei3YkHA8WYc49ne49fM.m3u8",
      title: "Avengers Endgame",
      description: "After the devastating events of Avengers: Infinity War (2018), the universe is in ruins. With the help of remaining allies, the Avengers assemble once more in order to reverse Thanos' actions and restore balance to the universe.",
    },
    campaign: {
      config: {
        initialLayout: "RIGHT-RAIL",
        type: "TRIVIA-VARIANT-1",
      },
      script: [
        {
          advertiser: "Lego",
          background: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-bg-2.jpg?alt=media&token=45791d24-131c-44b7-b407-1e07da7236b6",
          cta: {
            type: "REMOTE",
            title: "Trivia",
            description: "Learn More",
          },
          heroImage: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flego-spinning.gif?alt=media&token=f8016f32-f0a5-4a17-8df0-fa2b13a5883f",
          logo: "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Flogo-asset.png?alt=media&token=c53f8985-57b6-4f96-98f4-2ba2437592a3",
          message: {
            description: "Presented By",
            title: "Lego Trivia: Lego comes from the Danish term LEg GOdt. What do these words mean in English?",
          },
          trivia: [
            "Build Together",
            "Play Well",
            "Lets Go",
          ],
          type: "PAUSE-AD",
        },
      ]
    },
  },
];