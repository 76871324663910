export const nouns = {
  description: 'A list of Marvel themed nouns.',
  terms: [
    "Sorcerer",
    "Soldier",
    "Wolverine",
    "Star-Lord",
    "Spider",
    "Avenger",
    "Phoenix",
    "Hulk",
    "BlackWidow",
    "Symbiote",
    "Storm",
    "IronMan",
    "Thor",
    "Cyclops",
    "Beast",
    "Doctor",
    "Daredevil",
    "Panther",
    "Captain"
  ],
};
