import React from 'react';

function Metaverse() {
  return (
    <div className="metaverse">
      <p>Welcome to the metaverse!</p>
    </div>
  );
}

export default Metaverse;
