import './FeatureGatewayMessaging.scss';

import OpenInFullIcon from '@mui/icons-material/OpenInFull';


import { Button } from "@mui/material";

export function FeatureGatewayMessaging({ cta }) {
    return (
      <div className="feature-gateway-messaging">
        <div className="container center">
          <Button className=""><OpenInFullIcon />{cta.description}</Button>
        </div>
      </div>
    );
  }