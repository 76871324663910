import React, { useEffect } from 'react';

import ExperienceCard from '../../components/ExperienceCard';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import '../../styles/Experiences.scss';

function WatchExperiences(props) {
  const campaigns = props.campaigns;

  useEffect(() => {
    
  }, [campaigns]);

  return (
    <div className="experiences">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 2, md: 12 }}
        >
          {campaigns && campaigns.filter(campaign => campaign.type !== 'AD').map((campaign) => (
            <Grid item xs={1} sm={2} md={4} key={campaign.id}>
              <ExperienceCard campaign={campaign} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default WatchExperiences;
