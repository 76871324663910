import React, { useRef, useState, useEffect } from 'react';

import ExperienceCard from '../components/ExperienceCard';

import { experimentalStyled as styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import '../styles/Experiences.scss';

import { ADFORMATS } from '../helpers/adFormats';

function AdFormats() {
  const adFormats = ADFORMATS;

  useEffect(() => {
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <div className="experiences ad-formats">
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 2, md: 12 }}
        >
          {adFormats.map((adFormat, index) => (
            <Grid item xs={1} sm={2} md={4} key={index}>
              <ExperienceCard campaign={adFormat} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default AdFormats;