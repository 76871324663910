import { nameGenerator } from './nameGenerator';
import { randomAvatarGenerator } from './avatarGenerator';
import { v4 as uuidv4 } from 'uuid';

export const ADMIN_USERS = [
  'yasir@seismic.tv',
  'yhossain@gmail.com',
  'yasir.hossain@ext.disneystreaming.com',
];

export const MAX_CHAT_CAR_COUNT = 100;

export const LIVING_ROOM_IMG =
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room.jpg?alt=media&token=f85daff0-bc7a-4138-9ae8-52036e9329d8';

export const AVATARS = [
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F1.png?alt=media&token=4b786917-ca4f-4960-a4ed-24f091f2c158',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F2.png?alt=media&token=81006971-ed9c-482b-8f22-decaf943322a',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F3.png?alt=media&token=6a23842c-b1c6-4ef8-97f7-879d4c97996f',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F4.png?alt=media&token=beb6e8e9-f505-4c6c-ae12-ba2e0e13ab24',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F5.png?alt=media&token=75071fae-5c8a-4766-bc0d-450a217b5b90',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F6.png?alt=media&token=67ac46f1-1cc0-47c9-9797-33b8d1fcdb4f',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F7.png?alt=media&token=6fde997e-81e3-4c0b-923c-d653aa9e1e9a',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F8.png?alt=media&token=b0c2eaf0-7dd9-45f3-81d3-d63dc1e7a261',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F9.png?alt=media&token=ba70f7df-b668-4e80-aaf2-9e1ce00117b5',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F10.png?alt=media&token=cd1bede0-2df2-4197-bba0-2657859ac2fb',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F11.png?alt=media&token=9045545a-fa4d-4724-a66c-8f909ce4e08f',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F12.png?alt=media&token=5f1cfce6-7d7c-4168-b379-b158fef57429',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F13.png?alt=media&token=ee2a21a0-6908-4cb3-be13-c660fb66d41d',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F14.png?alt=media&token=7c532129-7b2c-4eeb-90de-0252844ef73f',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F15.png?alt=media&token=6e6aba02-a1dc-434e-856e-7d4957cd90a8',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F16.png?alt=media&token=7a563882-46cc-4398-b845-c7925c69a0aa',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F17.png?alt=media&token=4d223c4f-26ad-4be2-8607-7ca579eeb8c6',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F18.png?alt=media&token=edd6d55b-a5ef-4dca-b2e2-7ba65e2e3e91',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F19.png?alt=media&token=a78ab44d-8e0e-4292-b31c-39450acc92c1',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F20.png?alt=media&token=c00d5b0e-04ef-47ea-b541-8a69fcb94c4b',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F21.png?alt=media&token=1fa90542-739d-4145-af47-afec07ead819',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F22.png?alt=media&token=adc67951-511a-40bb-96f0-e498e43ad35e',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F23.png?alt=media&token=857b9764-3186-426c-a622-3fcba87aa5e1',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F24.png?alt=media&token=ddbfb974-3066-416e-92e3-220bd7d86d5d',
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/avatars%2F25.png?alt=media&token=55c81101-c992-4456-993c-95ca3009c763',
];

export const MOVIES = [
  'https://firebasestorage.googleapis.com/v0/b/ad-experiences.appspot.com/o/palm-springs.mp4?alt=media&token=01000601-9b14-4f61-935d-d90b92459ccf',
  'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
];

export const ANONYMOUS_USER = {
  accessToken: null,
  auth: null,
  displayName: null,
  email: null,
  emailVerified: false,
  isAnonymous: true,
  metadata: null,
  phoneNumber: null,
  photoUrl: null,
  avatarUrl: randomAvatarGenerator(AVATARS),
  chatName: nameGenerator(),
  uid: uuidv4(),
  providerId: null,
  devices: ['Web'],
  role: 'NORMAL',
  preferences: {
    chatEnabled: true,
  },
};

export const roles = {
  ADMIN: 'MODERATOR',
  CREATOR: 'CREATOR',
  NORMAL: 'NORMAL',
};

export const BG =
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/wallpaper.png?alt=media&token=8ca3cfe1-a518-4e0e-a0c7-fd99a5ae0532';

export const LOGO =
  'https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/logo.png?alt=media&token=06490322-79f1-4305-8fdc-b25d0889521c';
export const LOGO_DISNEY =
  'https://static-mh.content.disney.io/matterhorn/assets/goc/disney_logo_dark@2x-45d70f7dd57b.png';

export function filterByValue(array, string) {
  return array.filter(o => 
    Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
}

export const soundCorrect = "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/sound-effects%2Fcorrect.wav?alt=media&token=a2a7ef2a-cf12-4879-a61d-2b3bc5dba445";
export const soundIncorrect = "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/sound-effects%2Fincorrect.wav?alt=media&token=fc354ae1-99ad-4ec8-af0a-471e9196c4fb";
export const soundLeaderboard = "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/sound-effects%2Freveal.wav?alt=media&token=b158240b-3195-45c2-882a-31634c979fb9";
export const soundReward = "https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/sound-effects%2Fmagic-reveal.wav?alt=media&token=e7542e41-6603-47c6-b522-d888b4845386";