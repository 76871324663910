import React, { useRef, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../../Context';

import './PauseAd.scss';

import { db, analytics } from '../../modules/firebase';
import firebase from 'firebase/compat/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import AdBadge from '../../components/Ads/AdBadge';

import { SceneOverlay } from '../scenes/SceneOverlay';
import { SceneBillboard } from '../scenes/SceneBillboard';

import { CSSTransition } from 'react-transition-group';

function PauseAd({ campaign, currentButtonPress, viewable, paramOverride }) {
  const [isViewable, setIsViewable] = useState(viewable);
  const scenes = [SceneOverlay, SceneBillboard];
  const { eventId } = useParams();
  const [currentActive, setCurrentActive] = useState(true);
  const [seconds, setSeconds] = useState(60);
  const [resultsSeconds, setResultsSeconds] = useState(30);
  const { currentUser } = useContext(UserContext);
  const hasRunInitialSetup = useRef(false);
  const isMounted = useRef(false);

  // Supported Scenes
  const [currentScene, setCurrentScene] = useState(scenes[0]);
  const [pauseState, setPauseState] = useState(false);

  useEffect(() => {
    if (currentButtonPress && currentButtonPress.key === "Play/Pause") {
      setPauseState(!pauseState);
      if (pauseState) {
        firebase.analytics().logEvent('User Paused');
        firebase.analytics().logEvent('Pause Ad Impression');
      } else {
        firebase.analytics().logEvent('User Unpaused');
      }
    }
  }, [currentButtonPress]);

  useEffect(() => {
    isMounted.current = true;
    // Run only once
    if (!hasRunInitialSetup.current) {
      // pauses content for demonstration
      // send play/pause event to video player
      setTimeout(pauseContent, 5000);

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }
  }, [campaign]);

  const pauseContent = () => {
    // once paused, set pause add to appear in 5 seconds
  }

  return (
    <CSSTransition
      in={pauseState}
      timeout={500}
      classNames="fade"
      unmountOnExit
    >
      <div className="pause-ad">
        <AdBadge />
        {campaign && <SceneOverlay campaign={campaign} />}
      </div>
    </CSSTransition>
  );
}

export default PauseAd;
