import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import { db, analytics } from '../../modules/firebase';
import firebase from 'firebase/compat/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { UserContext } from '../../Context';

import AdBadge from '../Ads/AdBadge';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { soundCorrect, soundIncorrect } from '../../helpers/constants';

import '../../styles/InteractiveAd.scss';

function TriviaAdPod(props) {
  //const currentTime = props.currentTime;
  const campaign = props.campaign;
  const paramOverride = props.paramOverride;
  const liContainerRef = props.liContainerRef;
  const currentButtonPress = props.currentButtonPress;
  const active = props.active;
  const setActive = props.setActive;
  const playerState = props.playerState;
  const setPlayerState = props.setPlayerState;
  const { eventId } = useParams();
  const [total, setTotal] = useState(0);
  const increment = firebase.firestore.FieldValue.increment(1);
  const [myVote, setMyVote] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [currentActive, setCurrentActive] = useState(true);
  const [viewable, setViewable] = useState(false);
  const [seconds, setSeconds] = useState(15);
  const [resultsSeconds, setResultsSeconds] = useState(10);
  const { currentUser } = useContext(UserContext);
  const liRefs = useRef([]);
  const hasRunInitialSetup = useRef(false);
  const [currentLi, setCurrentLi]= useState(null);
  const [progress, setProgress] = React.useState(100);

  // TODO: Fix Debug Mode
  const dbRef = paramOverride ? 'avengers-endgame-trivia-edition' : eventId;
  const triviaRef = db.collection('polls').where('slug', '==', dbRef);

  // Trivia
  const triviaTotalAds = props.triviaTotalAds;
  const setTriviaTotalAds = props.setTriviaTotalAds;
  const triviaTotalCorrect = props.triviaTotalCorrect;
  const setTriviaTotalCorrect = props.setTriviaTotalCorrect;

  const [triviaPodTotal, setTriviaPodTotal] = useState(campaign.trivia.length);
  const [currentTrivia, setCurrentTrivia] = useState(null);
  const [currentTriviaPosition, setCurrentTriviaPosition] = useState(0);

  const [currentState, setCurrentState] = useState('TRIVIA');

  // Sound Effects
  const [audioCorrect] = useState(new Audio(soundCorrect));
  const [audioIncorrect] = useState(new Audio(soundIncorrect));

  useEffect(() => {
    // Run only once
    if (!hasRunInitialSetup.current) {
      loadData(0);

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }

    // Update handleKeyDown whenever currentButtonPress changes
    handleKeyDown();
  }, [currentButtonPress]);

  useEffect(() => {  
    if (currentState === 'RESULTS') {
      const resultsInterval = setInterval(() => {
        setResultsSeconds(resultsSeconds => resultsSeconds - 1);
      }, 1000);
      if (resultsSeconds === 0 && currentState === 'RESULTS') {
        nextTrivia();
      }
      return () => clearInterval(resultsInterval);
    }
  }, [resultsSeconds, currentState]);

  const UrgeWithPleasureComponent = () => (
    <CountdownCircleTimer
    isPlaying
    duration={15}
    size={80}
    strokeWidth={6}
    colors={'#F2DE3C'}
    onComplete={() => {
      setCurrentActive(false);
      setCurrentState('RESULTS');
      setSeconds(15);
      setResultsSeconds(10);
    }}>
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
  )

  const nextTrivia = () => {
    setCurrentTriviaPosition(currentTriviaPosition + 1);
    if ((currentTriviaPosition + 1) < (campaign.trivia.length)) {
      loadData(currentTriviaPosition + 1);
      setCurrentState('TRIVIA');
      setIsCorrect(false);
    } else {
      closeTrivia();
    }
  }

  const updateFocus = () => {
    const checkForLiElements = setInterval(() => {
      if (liContainerRef.current) {
        const liElements = liContainerRef.current.querySelectorAll('li');
        if (liElements.length > 0) {
          liElements[0].focus();
          setCurrentLi(liElements[0]);
          clearInterval(checkForLiElements);
        }
      }
    }, 1000); 
  }

  const loadData = async (newPosition) => {
    const triviaData = await triviaRef.get();
    const formattedTriviaData = triviaData.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCurrentTrivia(formattedTriviaData.find(item => item.id === campaign.trivia[newPosition]));
    setViewable(true);
    setCurrentActive(true);
    setActive(true);
    updateFocus();
  };

  const handleKeyDown = () => {
    const focusedElement = currentLi;
    
    if (liContainerRef.current && liContainerRef.current.contains(focusedElement)) {
      const focusedIndex = liRefs.current.indexOf(focusedElement);
    
      if (currentButtonPress) {
        currentButtonPress.preventDefault(); // Prevents scrolling
    
        if (currentButtonPress.key === 'ArrowDown') {
          const nextIndex = focusedIndex === liRefs.current.length - 1 ? 0 : focusedIndex + 1;
          liRefs.current[nextIndex].focus();
          setCurrentLi(liRefs.current[nextIndex]);
        } else if (currentButtonPress.key === 'ArrowUp') {
          const prevIndex = focusedIndex === 0 ? liRefs.current.length - 1 : focusedIndex - 1;
          liRefs.current[prevIndex].focus();
          setCurrentLi(liRefs.current[prevIndex]);
        } else if (currentButtonPress.key === 'Enter') {
          triviaSubmit(currentTrivia.options[currentLi.getAttribute('data')]);
        } else if (currentButtonPress.key == 'Delete') {
          closeTrivia();
        }
      }
    
      // getCurrentFocusPosition();
    }
  };

  const updateUserLeaderboard = async() => {
    const increaseBy = firebase.firestore.FieldValue.increment(currentTrivia.points);
    const leaderboard = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', dbRef);
    let userData = {
      uid: currentUser.uid,
      avatar: currentUser.avatarUrl,
      chatName: currentUser.chatName,
      score: increaseBy,
      banned: false,
    };
    
    await leaderboard.limit(1).get().then((query) => {
      const loadedLeaderboard = query.docs[query.docs.length - 1];
      const loadedLeaderboardRef = loadedLeaderboard.ref;
      return loadedLeaderboardRef.set({ [currentUser.uid]: userData }, {merge:true});
    });
  }

  const addUserToLeaderboard = async() => {
    const leaderboard = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', dbRef);
    let userData = {
      uid: currentUser.uid,
      avatar: currentUser.avatarUrl,
      chatName: currentUser.chatName,
      score: currentTrivia.points,
      banned: false,
    };

    console.log(leaderboard);

    await leaderboard.limit(1).get().then((query) => {
      const loadedLeaderboard = query.docs[query.docs.length - 1];
      const loadedLeaderboardRef = loadedLeaderboard.ref;
      return loadedLeaderboardRef.set({ [currentUser.uid]: userData }, {merge:true});
    });
  }

  function triviaSubmit(vote) {
    if (currentActive) {
      setMyVote(vote);
      if (vote.label === currentTrivia.answer) {
        setIsCorrect(true);
        audioCorrect.play();
        console.log('you got it right!');

        // Send to update score within leaderboard
        const leaderboardRef = db.collection('leaderboard').where(firebase.firestore.FieldPath.documentId(), '==', dbRef);
        const userLeaderboardRef = leaderboardRef.where(firebase.firestore.FieldPath.documentId(), '==', currentUser.uid);
  
        leaderboardRef.limit(1).get().then((query) => {
          console.log('attempting to submit to leaderboard');
          console.log(query);
          // See if user is on the leaderboard
          if (!query.empty) {
            console.log('event leaderboard exists in db');
            const leaderboard = query.docs[0].data();
  
            if (leaderboard[currentUser.uid] !== undefined) {
              // User exists in record and update score
              console.log('user exists in leaderboard!');
              updateUserLeaderboard();
            } else {
              // User does not exist and need to add to record
              console.log('user record doesnt exist in leaderboard');
              addUserToLeaderboard();
            }
          }
        });
      } else {
        audioIncorrect.play();
      }
  
      let triviaSnapshot = currentTrivia;
      triviaSnapshot.options.map((response) => {
        if (response.label === vote.label) {
          response.total++;
          triviaSnapshot.total++;
        }
      });

      db.collection('polls')
      .where('id', '==', currentTrivia.id)
      .where('title', '==', currentTrivia.title)
      .limitToLast(1)
      .orderBy('time')
      .get()
      .then((query) => {
        // Getting 2 items in case there is a duplicate, and applying to latest poll in array
        // TODO: possible to move this to a new collection under campaign vs currently in seperate polls collection
        const loadedTrivia = query.docs[query.docs.length - 1];
        const loadedTriviaRef = loadedTrivia.ref;
        loadedTriviaRef.update(triviaSnapshot);
        /*
        loadedTrivia.ref.update({
          total: increment,
        });
        */
      });

      setCurrentActive(false);
      setCurrentState('RESULTS');
      setResultsSeconds(10);

      firebase.analytics().logEvent('trivia_played');
    }
  }

  function closeTrivia() {
    setViewable(false);
    setCurrentActive(false);
    setActive(false);
  }

  const highlightOffer = (offerMessage) => {
    const highlightColor = "yellow";
    const regex = /(\d+)% off/gi;

    if (!offerMessage) {
      return offerMessage;
    }

    const matches = offerMessage.match(regex);
    const parts = offerMessage.split(regex);
    
    return (
      <React.Fragment key={0}>
        {parts[0]}
        {matches && matches[0] ? (
          <span style={{ color: highlightColor }}>
            {matches[0]}
          </span>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <div className={'ad-format-type triviaAd ' + (currentActive ? 'active ' : 'inactive ') + (viewable ? 'viewable ' : '')} 
    style={{backgroundImage: viewable ? `url(${campaign.background})` : `none`}}
    >
      {viewable && currentTrivia
        ? [
          <>
            <AdBadge seconds={(seconds + resultsSeconds) * campaign.trivia.length} />
            { currentState === 'TRIVIA' && currentActive ? [
              <div className="countdown-timer">
                <label>Time Remaining</label> { UrgeWithPleasureComponent() }
              </div>
            ] : null }
            <div className="trivia-container" key={currentTrivia.id}>
              <div className="component-container">
                <div className="text-container">
                  { currentActive ? [ 
                    <>
                    <h2 className="question">Question</h2>
                    <h2 key="question">{currentTrivia.title}</h2> 
                    </>
                  ] : [
                    <>
                    <h2 className="answer">Answer</h2>
                    <h2 key="answer">{currentTrivia.answerDetail}</h2> 
                    </>
                  ]}
                </div>
                <ol className={`list-unstyled ` + (!currentActive ? 'inactive' : '')} ref={liContainerRef} tabIndex="0">
                  {currentTrivia.options.map((option, index) => {
                    let pollStyle = {
                      width: `${(option.total / currentTrivia.total) * 100}%`,
                    };
                    let optionValue = option.total;
                    if (optionValue !== 0) {
                      optionValue = (
                        (option.total / currentTrivia.total) *
                        100
                      ).toFixed(0);
                    }
                    return (
                      <li
                        key={index}
                        onClick={(e) => triviaSubmit(option)}
                        data={index}
                        ref={el => (liRefs.current[index] = el)}
                        tabIndex="-1"
                        className={
                          (!currentActive && (currentTrivia.answer === option.label) ? 'correct ' : '') +
                          (myVote && !currentActive && currentTrivia.answer === myVote.label && myVote.label === option.label ? 'correct ' : '') +
                          (myVote && !currentActive && currentTrivia.answer !== myVote.label && myVote.label === option.label ? 'incorrect ' : '')
                        }
                      >
                        <label className="title" key={`${index}-title`}>{option.label}</label>
                        {!currentActive ? [
                            <>
                              {currentTrivia.answer === option.label ? (
                                <CheckIcon key={`${index}-correct-icon`} />
                              ) : (
                                <ClearIcon key={`${index}-incorrect-icon`} />
                              )}
                            </>
                          ] : null}
                      </li>
                    );
                  })}
                </ol>
                <div className="results">
                  {isCorrect && !currentActive
                    ? [<span className="response correct" key="corrrect">Correct! +{currentTrivia.points} points</span>]
                    : null}
                  {!isCorrect && !currentActive
                    ? [<span className="response" key="incorrect">Better luck next time!</span>]
                    : null}
                </div>
              </div>
            </div>
            <div className="advertiser-message">
              <div className="ad-message-container">
                <label className="ad-message-one">{ campaign.message.title }</label>
                <label className="ad-message-two">{ campaign.message.description }</label>
              </div>
              <img src={campaign.logo} alt={campaign.advertiser} />
            </div>
            <div className="advertiser-prompt">
              <img src="https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/campaigns%2Fremote-apple-tv.png?alt=media&token=f16d702b-8716-4865-b488-d560bb5ea751" alt="Apple TV Remote" />
              <div className="ad-prompt-container">
                <label className="ad-offer-message">{highlightOffer(campaign.offer.title)}</label>
                <label className="ad-cta-prompt">{campaign.cta[0].description}</label>
              </div>
            </div>
            <div className="gradient-background"></div>
           </>       
          ]
        : null}
    </div>
  );
}

export default TriviaAdPod;
