import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../Context';

import { TestVideoPlayer } from '../components/TestVideoPlayer';
import Remote from '../components/Remote';
import LowerThird from '../components/Ads/LowerThird';
import TriviaAdPod from '../components/Ads/TriviaAdPod';
import EndCard from '../components/Ads/EndCard';
import EndCardTrivia from '../components/Ads/EndCardTrivia';

import PauseAd from '../dxc/templates/PauseAd';

import '../styles/Experience.scss';
import '../styles/InteractiveAd.scss';

import { ADFORMATS } from '../helpers/adFormats';

function AdFormat(props) {
  const adFormats = ADFORMATS;
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const { eventId } = useParams();
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [currentTime, setCurrentTime] = useState(0.00);
  const [currentButtonPress, setCurrentButtonPress] = useState(null);
  const liContainerRef = useRef(null);
  const [active, setActive] = useState(true);
  const [viewable, setViewable] = useState(true);
  // const { currentUser } = useContext(UserContext);

  // Trivia
  const triviaTotalAds = props.triviaTotalAds;
  const setTriviaTotalAds = props.setTriviaTotalAds;
  const triviaScore = props.triviaScore;
  const setTriviaScore = props.setTriviaScore;
  const triviaTotalCorrect = props.triviaTotalCorrect;
  const setTriviaTotalCorrect = props.setTriviaTotalCorrect;

  const handleKeyDown = (event) => {
    console.log(`Keydown event: ${event.key}`);
    setCurrentButtonPress(event);
    // Perform actions based on the dispatched event
  };

  useEffect(() => {
    setCurrentCampaign(adFormats && adFormats.find((c) => c.slug === eventId));

    document.addEventListener('keydown', handleKeyDown);

    console.log(currentCampaign);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };    
  }, [currentCampaign]);

  return (
    <div className={
      (currentCampaign && currentCampaign.config.atLaunch === "VIDEO" ? 'live ' : '') +
      (currentCampaign && currentCampaign.config.deviceType === "CTV" ? 'ctv ' : '') +
      'chatDisabled experience ad-format squeezeback'
    }>
      { currentCampaign && currentCampaign.config.deviceType === "CTV" ? [
        <Remote liContainerRef={liContainerRef} key="remote" />
      ] : null }

      <section className="main">
        <div className={(currentCampaign && currentCampaign.campaign.script[0].type ? currentCampaign.campaign.script[0].type : '') + ` video-player-container`}>
          {currentCampaign && currentCampaign.config && currentCampaign.config.atLaunch === "VIDEO" ? [
            <TestVideoPlayer key="video-player" video={currentCampaign.video.source} currentTime={currentCampaign.video.config.time} />
          ] : null}


        <div className="interactive-content" id="interactiveContent" key="interactive-content">
          { currentCampaign && currentCampaign.campaign.script[0].type === "PROMPT" && active && viewable ? [
            <LowerThird 
              campaign={currentCampaign.campaign.script[0]} 
              currentButtonPress={currentButtonPress} 
              video={currentCampaign.video.source}
              active={active}
              setActive={setActive}
              key="lower-third" 
            />
          ] : currentCampaign && currentCampaign.campaign.script[0].type === "TRIVIAADPOD" && active && viewable ? [
            <TriviaAdPod 
              liContainerRef={liContainerRef} 
              campaign={currentCampaign.campaign.script[0]} 
              currentButtonPress={currentButtonPress} 
              active={active} 
              setActive={setActive} 
              currentTime={currentTime} 
              video={currentCampaign.video.source}
              triviaTotalAds={triviaTotalAds}
              setTriviaTotalAds={setTriviaTotalAds}
              triviaScore={triviaScore}
              setTriviaScore={setTriviaScore}
              triviaTotalCorrect={triviaTotalCorrect}
              setTriviaTotalCorrect={setTriviaTotalCorrect}
              paramOverride={"avengers-endgame-trivia-edition"}
              key="trivia-ad-pod" 
            />
          ] : currentCampaign && currentCampaign.campaign.script[0].type === "ENDCARD" && active && viewable ? [
            <EndCard 
              campaign={currentCampaign.campaign.script[0]} 
              currentButtonPress={currentButtonPress} 
              active={active} 
              setActive={setActive} 
              key="end-card" 
            />
          ] : currentCampaign && currentCampaign.campaign.script[0].type === "ENDCARD-TRIVIA" && active && viewable ? [
            <EndCardTrivia 
              campaign={currentCampaign.campaign.script[0]} 
              currentButtonPress={currentButtonPress} 
              active={active} 
              setActive={setActive} 
              paramOverride={"avengers-endgame-trivia-edition"}
              key="end-card-trivia" 
            />
          ] : currentCampaign && currentCampaign.campaign.script[0].type === "PAUSE-AD" && active && viewable ? [
            <PauseAd
              campaign={currentCampaign.campaign.script[0]} 
              currentButtonPress={currentButtonPress} 
              viewable={true}
              paramOverride={"avengers-endgame-trivia-edition"}
              key="pause-ad" 
            />
          ] : null }
          </div>
        </div>

        <div 
          className="bg" 
          style={{
              backgroundImage:`url("https://firebasestorage.googleapis.com/v0/b/showintel-8dcf8.appspot.com/o/living-room-bg-alt-2.jpg?alt=media&token=9aaf7aac-1124-4466-91f3-5fdab26fe203")`,
              backgroundSize: 'cover'
            }}>    
        </div>
      </section>
    </div>
  );
}

export default AdFormat;