import React, { useState, useEffect, useContext, useRef } from 'react';

import '../../styles/AdBadge.scss';

function AdBadge(props) {
  const [seconds, setSeconds] = useState(props.seconds);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  // Format seconds to have leading zero if less than 10
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  useEffect(() => {
    const adInterval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
      if (seconds === 0) {
        setSeconds(0);
      }
    }, 1000);
    return () => clearInterval(adInterval);
  }, [seconds]);

  return (
    <div className={'ad-badge'}>
      <label className="badge">Ad</label>
      { seconds > 0 ? <label className="time">{minutes}:{formattedSeconds}</label> : null }
    </div>
  );
}

export default AdBadge;
