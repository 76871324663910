import React, { useRef, useState, useEffect, useContext } from 'react';
import VideoJS from '../components/VideoJS';
import { PlayerContext } from '../Context';

import '../styles/VideoPlayer.scss';

import InteractiveContent from './InteractiveContent';

import { db, analytics } from '../modules/firebase';

export const VideoPlayer = (props) => {
  const playerRef = useRef(null);
  const currentTime = props.currentTime;
  const setCurrentTime = props.setCurrentTime;
  const setCurrentState = props.setCurrentState;
  const currentButtonPress = props.currentButtonPress;
  const videoSrc = props.video;
  const liContainerRef = props.liContainerRef;
  const currentCampaign = props.currentCampaign;

  // Trivia
  const triviaTotalAds = props.triviaTotalAds;
  const setTriviaTotalAds = props.setTriviaTotalAds;
  const triviaScore = props.triviaScore;
  const setTriviaScore = props.setTriviaScore;
  const triviaTotalCorrect = props.triviaTotalCorrect;
  const setTriviaTotalCorrect = props.setTriviaTotalCorrect;
  const [requestedTime, setRequestedTime] = useState(0);
  const [active, setActive] = useState(false);
  const [interactiveType, setInteractiveType] = useState('');
  const [adHasVideo, setAdHasVideo] = useState(false);
  const { playerState, setPlayerState } = useContext(PlayerContext);

  const hasRunInitialSetup = useRef(false);

  useEffect(() => {
    // Run only once
    if (!hasRunInitialSetup.current) {
      loadPlayer();

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }

    const playerStateRef = db.collection('player').doc(currentCampaign.slug);
    const unsubscribe = playerStateRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (playerState.sources[0].src !== data.sources[0].src) {
          setPlayerState({
            ...playerState,
            sources: [{
              src: data.sources[0].src,
              type: data.sources[0].type,
            }],
          });
        }
        setRequestedTime(data.event.time);
      }
    });

    return () => {
      unsubscribe(); // Clean up the listener when the component unmounts
    };
  }, [currentCampaign]);

  const loadPlayer = () => {
    setPlayerState({
      autoplay: true,
      controls: true,
      responsive: true,
      fluid: true,
      fill: true,
      sources: [{
        src: videoSrc,
        type: 'application/x-mpegURL'
      }]
    });

    if (currentCampaign?.campaign !== undefined) {
      // Jumps to campaign requested time location
      setRequestedTime(currentCampaign.video.time);
    }
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    player.currentTime(requestedTime);

    player.on('ended', () => {
      if (playerRef.current.video?.src === undefined) {
        setPlayerState({
          ...playerState,
          sources: [{
            src: videoSrc,
            type: 'application/x-mpegURL'
          }]
        });
        setCurrentTime(10);
      } 
    });

    player.on('timeupdate', () => {
      setCurrentTime(player.currentTime());
    });
  };

  return (
    <div className={(adHasVideo ? 'ad-video ' : '') + (active && interactiveType ? interactiveType : '') + ` video-player`}>
      { currentCampaign && currentCampaign.campaign.script ? [
          <InteractiveContent 
            liContainerRef={liContainerRef}
            active={active}
            currentButtonPress={currentButtonPress}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            setActive={setActive}
            playerRef={playerRef}
            playerState={playerState}
            setPlayerState={setPlayerState}
            setRequestedTime={setRequestedTime}
            campaign={currentCampaign && currentCampaign.campaign}
            setInteractiveType={setInteractiveType} 
            setAdHasVideo={setAdHasVideo}
            video={videoSrc}
            triviaTotalAds={triviaTotalAds}
            setTriviaTotalAds={setTriviaTotalAds}
            triviaScore={triviaScore}
            setTriviaScore={setTriviaScore}
            triviaTotalCorrect={triviaTotalCorrect}
            setTriviaTotalCorrect={setTriviaTotalCorrect}
            key="interactive-content" 
          /> 
       ] : null }
      <VideoJS options={playerState} onReady={handlePlayerReady} requestedTime={requestedTime} />
    </div>
  );
};
