import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router';
import Lottie from 'lottie-web';
import { db, analytics } from '../../modules/firebase';
import { UserContext } from '../../Context';

import AdBadge from '../Ads/AdBadge';
import { soundLeaderboard, soundReward } from '../../helpers/constants';
import animFireworksData from '../../helpers/animations/confetti.json'; 

import Button from '@mui/material/Button';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CheckIcon from '@mui/icons-material/Check';

import '../../styles/InteractiveAd.scss';

function EndCardTrivia(props) {
  const campaign = props.campaign;
  const paramOverride = props.paramOverride;
  const liContainerRef = props.liContainerRef;
  const currentButtonPress = props.currentButtonPress;
  const setActive = props.setActive;
  const { eventId } = useParams();
  const [currentActive, setCurrentActive] = useState(true);
  const [viewable, setViewable] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const [resultsSeconds, setResultsSeconds] = useState(30);
  const { currentUser } = useContext(UserContext);
  const hasRunInitialSetup = useRef(false);
  const isMounted = useRef(false);
  const [toastViewable, setToastViewable] = useState(false);

  // TODO: Fix Debug Mode
  const dbRef = paramOverride ? 'avengers-endgame-trivia-edition' : eventId;

  const [currentState, setCurrentState] = useState('LEADERBOARD');

  const [topScorers, setTopScorers] = useState(null)
  const [remainingScorers, setRemainingScorers] = useState(null);
  const [offer, setOffer] = useState(null);

  // Sound Effects
  const [audioLeaderboard] = useState(new Audio(soundLeaderboard));
  const [audioReward] = useState(new Audio(soundReward));

  useEffect(() => {
    isMounted.current = true;
    // Run only once
    if (!hasRunInitialSetup.current) {
      loadLeaderboard();

      setTimeout(loadLottie, 1000);

      // Mark that the initial setup has run
      hasRunInitialSetup.current = true;
    }

    // Update handleKeyDown whenever currentButtonPress changes
    handleKeyDown();

    return () => {
      isMounted.current = false;
    };
  }, [currentButtonPress]);

  useEffect(() => {  
    if (currentState === 'LEADERBOARD') {
      const resultsInterval = setInterval(() => {
        setResultsSeconds(resultsSeconds => resultsSeconds - 1);
      }, 1000);
      if (resultsSeconds === 0 && currentState === 'LEADERBOARD') {
        showReward();
      }
      return () => clearInterval(resultsInterval);
    }
  }, [resultsSeconds, currentState]);

  const loadLottie = () => {
    const animFireworks = Lottie.loadAnimation({
      container: document.getElementById('lottie-container'), // Replace with your container element
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animFireworksData, // Animation JSON data
    });

    // Clean up on unmount
    return () => {
      animFireworks.destroy();
    };
  }

  const handleKeyDown = () => {
    if (currentButtonPress && currentButtonPress.key === 'Enter') {
      if (currentState === 'LEADERBOARD') {
        showReward();
      } else {
        sendToPhone();
      }
    } else if (currentButtonPress && currentButtonPress.key == 'Delete') {
      if (currentState === 'LEADERBOARD') {
        close();
      } else {
        showLeaderboard();
      }
    }
  };

  function showReward() {
    setCurrentState('REWARD');
    audioReward.play();
  }

  function showLeaderboard() {
    setCurrentState('LEADERBOARD');
    setViewable(true);
    setCurrentActive(true);
    setActive(true);
    audioLeaderboard.play();
    // renderAnimation();
  }

  function close() {
    setViewable(false);
    setCurrentActive(false);
    setActive(false);
  }

  const loadLeaderboard = async () => {
    try {
      const leaderboard = db.collection('leaderboard').doc(dbRef);
      const leaderboardData = await leaderboard.get();
  
      if (leaderboardData.exists) {
        const { id, ...restData } = leaderboardData.data();
        const users = Object.values(restData).filter(value => typeof value === 'object');
        const sortedLeaderboard = users.slice().sort((a, b) => b.score - a.score);

        // Get the top scorer
        const topScorer = sortedLeaderboard.shift(); // Remove the top scorer from the array

        // Get the top 2 scorers
        const topScorers = sortedLeaderboard.slice(0, 2);
        // Insert the top scorer back into the middle of the array
        topScorers.splice(1, 0, topScorer);
        setTopScorers(topScorers);
        const isCurrentUserTopScorer = topScorers.some(scorer => scorer.uid === currentUser.uid);

        // Get the remaining entries
        const remainingScorers = sortedLeaderboard.slice(2);
        setRemainingScorers(remainingScorers);
        const isCurrentRemainingScorers = remainingScorers.some(scorer => scorer.uid === currentUser.uid);
  
        if (isCurrentUserTopScorer) {
          setOffer(campaign.offer.amount[2]);
        } else if (isCurrentRemainingScorers) {
          setOffer(campaign.offer.amount[1]);
        } else {
          setOffer(campaign.offer.amount[0]);
          setSeconds(30);
        }

        showLeaderboard();
      } else {
        console.log('Leaderboard not found');
      }
    } catch (error) {
      console.error('Error loading leaderboard:', error);
    }
  };

  function sendToPhone() {
    console.log('Sending Offer to Phone');
    setToastViewable(true);
  }

  return (
    <div className={'ad-format-type endCardAd ' + (currentActive ? 'active ' : 'inactive ') + (viewable ? 'viewable ' : '')} 
    style={{backgroundImage: viewable ? `url(${campaign.background})` : `none`}}
    >
      {viewable && currentState === 'LEADERBOARD' ? [
        <>
          <AdBadge seconds={seconds} />
          <div className="component-container leaderboard-container">
              { topScorers && topScorers.length > 1 ? [
              <>
                <ol className="top-scorers">
                  {topScorers.map((option, index) => {
                    return (
                      <>
                        { (option.uid === currentUser.uid) ? [
                          <li
                          key={`top-scorer-${index}-self`}
                          className={"self"}
                          style={{backgroundImage: `url(${option.avatar})`}}
                          >
                            <label className="rank">{(index === 0) ? 2 : (index === 1) ? 1 : 3}</label>
                            <label className="score">{option.score}pts</label>
                          </li>
                        ] : [
                          <li
                          key={`top-scorer-${index}`}
                          className={"option"}
                          style={{backgroundImage: `url(${option.avatar})`}}
                          >
                            <label className="rank">{(index === 0) ? 2 : (index === 1) ? 1 : 3}</label>
                            <label className="score">{option.score}pts</label>
                          </li>
                        ]
                      }
                      </>
                    );
                  })}
                </ol>
                <div id="lottie-container"></div>
              </>
              ] : null}
              { remainingScorers && topScorers.length > 1 ? [ 
                <ol className="remaining-scorers list-unstyled" ref={liContainerRef} tabIndex="0">
                  { remainingScorers.some(option => option.uid === currentUser.uid) ? [
                    <li
                      key={`scorer-self`}
                      className={"self"}
                    >
                      <label className="name">{currentUser.chatName}</label>
                      <label className="score">{remainingScorers.find(option => option.uid === currentUser.uid).score}</label>
                    </li>
                  ] : null }
                  {remainingScorers.map((option, index) => {
                    return (
                      <>
                        { (option.uid !== currentUser.uid) ? [
                          <li
                          key={`scorer-${index}`}
                          className={"option"}
                          >
                            <label className="name">{option.chatName}</label>
                            <label className="score">{option.score}pts</label>
                          </li>
                        ] : null }
                      </>
                    );
                  })}
                </ol>
              ] : null }
          </div>
          <div className="gradient-background"></div>
        </>       
      ] : null}

      {viewable && currentState === 'REWARD' ? [
        <div className="reward">
          { offer > 0 ? [
            <>
            <div className="achievement-container">
              <div className="badge"></div>
            </div>
            <div className="offer-container">
              <h1>Congratulations!</h1>
              <p>Your impressive trivia skills have earned you an <span>exclusive {offer}% discount</span> with { campaign.advertiser } and a <span>{ campaign.achievement.title } badge</span> 🏆! Enjoy the recognition for your quiz mastery!</p>
              <Button className="gateway-tech" onClick={sendToPhone}><PhoneIphoneIcon /> Send to Phone</Button>
            </div>
            </>
          ] : [
            <div className="offer-container">
              <h1>Play and Win with {campaign.advertiser}!</h1>
              <p>Gear up for your next chance to win exciting offers and climb the ranks on our leaderboard!</p>
              <Button className="gateway-tech" onClick={sendToPhone}><NotificationsActiveIcon /> Remind Me</Button>
            </div>
          ] }
        </div>
      ] : null}
      <div key="toast" className={`toast ${toastViewable ? 'viewable' : 'hidden'}`}>
        <div className="advertiser-prompt">
          <div className="countdown-timer">
            <CheckIcon key={`check-icon`} />
          </div>
          <div className="ad-prompt-container">
            <label className="title">Sent to Phone!</label>
            <label className="sub-title"></label>
          </div>
        </div>
      </div>
      <div className="advertiser-message">
        <div className="ad-message-container">
          <label className="ad-message-one">{ campaign.message.title }</label>
          <label className="ad-message-two">{ campaign.message.description }</label>
        </div>
        <img src={campaign.logo} alt={campaign.advertiser} />
      </div>
    </div>
  );
}

export default EndCardTrivia;