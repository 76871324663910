import { Routes as BaseRoutes, Route } from 'react-router-dom';

import Home from './pages/Home';
import WatchExperiences from './pages/Experiences/WatchExperiences';
import AdExperiences from './pages/Experiences/AdExperiences';
import Experience from './pages/Experiences/Experience';
import AdFormats from './pages/AdFormats';
import AdFormat from './pages/AdFormat';
import Admin from './pages/Admin';
import Metaverse from './pages/Metaverse';
import AdBuilder from './pages/AdBuilder';

export default function Routes(props) {
  return (
    <BaseRoutes>
      <Route path="/" element={<Home />} />
      <Route path="watch-experiences">
        <Route index element={<WatchExperiences campaigns={props.campaigns} />} />
        <Route
          path=":eventId"
          element={<Experience campaigns={props.campaigns} />}
        />
      </Route>
      <Route path="ad-experiences">
        <Route index element={<AdExperiences campaigns={props.campaigns} />} />
        <Route
          path=":eventId"
          element={<Experience campaigns={props.campaigns} />}
        />
      </Route>
      <Route path="ad-formats">
        <Route index element={<AdFormats />} />
        <Route
          path=":eventId"
          element={<AdFormat />}
        />
      </Route>
      <Route path="admin" element={<Admin campaigns={props.campaigns} />} />
      <Route path="metaverse" element={<Metaverse />} />
      <Route path="ad-builder" element={<AdBuilder />} />
    </BaseRoutes>
  );
}
